/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import GlideCarousel from "~components/GlideCarousel";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

import { AppContext } from "~context/AppContext";

function TourPageComponent(props) {
  const {
    description,
    handle,
    imageGallery,
    itinerary,
    // reviews,
    tagline,
    title,
    tourOptions,
    whatToExpect
  } = props.frontmatter;

  return (
    <>
      <SEO
        frontmatterTitle={title}
        frontmatterDescription={description}
        pathName={handle}
      />

      <Layout className="tour-page relative z-10 text-dark-grey mb-24">
        <section className="tour-page__intro grid mx-8">
          <div className="grid-end--9 grid-end--xs-12 pb-16">
            <h1 className="f1 animation-appear pb-4">
              <span>{tagline}&nbsp;</span>
              <br />
              <span
                className="font-bold animation-appear"
                style={{ animationDelay: `200ms` }}
              >
                {title}.
              </span>
            </h1>

            <p
              className="f4 mt-4 animation-appear"
              style={{ animationDelay: `300ms` }}
            >
              {description}
            </p>
          </div>

          <GlideCarousel
            className="tour-page__carousel grid-end--12 relative mt-16 animation-appear"
            colorClass="black"
            glideClass="images-glide"
            slideData={imageGallery}
            style={{ animationDelay: `400ms` }}
          >
            {imageGallery.map((image, index) => {
              if (
                image.src &&
                image.src.childImageSharp &&
                image.src.childImageSharp.fluid
              ) {
                const imageIndex = index;
                return (
                  <li key={`image-${imageIndex}`} className="flex">
                    <Img
                      backgroundColor="#EEEEEE"
                      className="index-page__carousel__image m-16 w-full h-auto justify-center items-center"
                      key={imageIndex}
                      fluid={image.src.childImageSharp.fluid}
                      objectPosition="50% 50%"
                      objectFit="contain"
                    />
                  </li>
                );
              }
              return null;
            })}
          </GlideCarousel>
        </section>

        <section className="tour-page__about w-full relative grid pt-16">
          <h3 className="grid-end--12 f3 font-bold">About This Workshop</h3>

          {itinerary && (
            <div className="tour-page__about--row grid-end--9 grid-end--xs-12 mt-4">
              <span className="font-bold">Itinerary</span>
              <p dangerouslySetInnerHTML={{ __html: itinerary }}></p>
            </div>
          )}

          {whatToExpect && (
            <div className="tour-page__about--row grid-end--9 grid-end--xs-12 my-4">
              <span className="font-bold">What to expect</span>
              <p dangerouslySetInnerHTML={{ __html: whatToExpect }}></p>
            </div>
          )}

          <ul className="tour-page__about--row grid-end--12 grid font-bold pt-4 border-b border-dark-grey border-solid pb-2">
            <li className="grid-end--4 grid-end--xs-4">Dates</li>
            <li className="grid-end--4 grid-end--xs-4">Price</li>
            <li className="grid-end--4 xs:hidden">Express Interest</li>
          </ul>

          {tourOptions && tourOptions.length ? (
            tourOptions.map((tourOption, index) => {
              const tourOptionKey = index;

              return (
                <ul
                  key={tourOptionKey}
                  className="tour-page__about--row grid-end--12 grid flex flex-row items-center border-b border-dark-grey border-solid pt-1 pb-2"
                >
                  <li className="grid-end--4 grid-end--xs-4">
                    {tourOption.dateFrom} - {tourOption.dateTo}
                  </li>
                  <li className="grid-end--4 grid-end--xs-4">
                    {tourOption.price}
                  </li>
                  <li className="grid-end--4 grid-end--xs-12 xs:py-4">
                    <a
                      href={`mailto:joseph@jbphotohouse.com.au?subject=${title} photography workshop (${tourOption.dateFrom})&body=Hi, I'm interested in the ${title} photography workshop on the ${tourOption.dateFrom}.%0D%0A %0D%0A Number of spots: %0D%0A %0D%0A Additional Comments: `}
                    >
                      <button
                        type="button"
                        className="w-full tour-page__about--button rounded-sm bg-dark-grey text-white border-dark-grey border border-solid hover:bg-light-grey hover:text-dark-grey px-2 py-1"
                      >
                        Book now
                      </button>
                    </a>
                  </li>
                </ul>
              );
            })
          ) : (
            <section className="grid-end--12">
              <div className="tour-page__about--row flex flex-row justify-center my-4">
                No tours currently available for {title}.
              </div>
              <div className="flex flex-row justify-center items-center">
                <button
                  type="button"
                  className="tour-page__about--button rounded-sm bg-dark-grey text-white border-dark-grey border border-solid hover:bg-light-grey hover:text-dark-grey px-2 py-1"
                >
                  Register your interest
                </button>
              </div>
            </section>
          )}
          <div className="grid-end--12 mt-8 b2">
            Or email&nbsp;
            <a
              className="underline"
              href="mailto:joseph@jbphotohouse.com.au?subject=General Workshop Booking"
            >
              joseph@jbphotohouse.com.au
            </a>
            &nbsp;to book.
          </div>
        </section>
      </Layout>
    </>
  );
}

const TourPage = ({ data }) => {
  const { siteMetadata: metadata } = data.site;
  const { frontmatter } = data.markdownRemark;

  return (
    <AppContext.Consumer>
      {appContext => (
        <TourPageComponent
          appContext={appContext}
          frontmatter={frontmatter}
          metadata={metadata}
        />
      )}
    </AppContext.Consumer>
  );
};

export default TourPage;

export const TourPageQuery = graphql`
  query TourPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        handle
        imageGallery {
          src {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        itinerary
        whatToExpect
        keywords
        reviews {
          name
          review
        }
        tagline
        title
        tourOptions {
          dateFrom
          dateTo
          spotsAvailable
          spotsTotal
          price
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        keywords
        author
        url
        twitterUsername
      }
    }
  }
`;
